
import router from "./../router";
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
  onMounted,
  createVNode,
} from "vue";
import { useRoute } from "vue-router";
import { notification, Modal } from "ant-design-vue";
import {
  CheckCircleFilled,
  HeartOutlined,
  ClockCircleOutlined,
  BellOutlined,
  DeleteOutlined,
  CopyOutlined,
  ClockCircleFilled,
  CloseCircleOutlined
} from "@ant-design/icons-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import validate from "../services/validator";
import moment from "moment";
import candidateService from "../services/candidate";

import addNewRefereeModal from "@/components/addNewReferee.vue";

export default defineComponent({
  components: {
    CheckCircleFilled,
    ClockCircleOutlined,
    BellOutlined,
    DeleteOutlined,
    addNewRefereeModal,
    CopyOutlined,
    ClockCircleFilled,
  },
  setup() {
    const route = useRoute();
    const pageLoader = ref<boolean>(false);
    const accessToken = ref<string>(route.params.token as string);
    const addReferee = ref<boolean>(false);
    const deleteRefereLoading = ref<boolean>(false);
    const refereRemainderLoading = ref<boolean>(false);
    const activeKey = ref([]);
    const candidateDetails = ref();
    const copyUrl = ref();
    const clone = ref(null);
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const getCandidateDetails = async (id: any) => {
      pageLoader.value = true;
      try {
        const data = {
          url_token: id,
        };
        const res = await candidateService.getWebCandidateDetails(data);
        if (res.data.data.success) {
          candidateDetails.value = res.data.data.candidate;
        }
        pageLoader.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoader.value = false;
        }
      }
    };
    const sendRefereeRemindar = async (data: any) => {
      refereRemainderLoading.value = true;
      try {
        const res = await candidateService.refereeRemainder(data.id);
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
          getCandidateDetails(accessToken.value);
        }
        refereRemainderLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.data.message, "error");
          refereRemainderLoading.value = false;
        }
      }
    };
    const showDeleteConfirm = async (referee: any) => {
      Modal.confirm({
        title: "Are you sure?",
        icon: createVNode(CloseCircleOutlined),
        content: createVNode(
          "p",
          {},
          "Do you really want to delete this referee? This process cannot be undone."
        ),
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        class: "delte-confirm-modal",
        async onOk() {
          await deleteReferee(referee);
        },
      });
    };
    const deleteReferee = async (data: any) => {
      deleteRefereLoading.value = true;
      const params = {
        url_token: accessToken.value,
        referee_id: data.id,
      };
      try {
        const res = await candidateService.webCandidateDeleteReferee(params);
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
          getCandidateDetails(accessToken.value);
        }
        deleteRefereLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.data.message, "error");
          deleteRefereLoading.value = false;
        }
      }
    };
    const addNewReferee = async () => {
      addReferee.value = true;
      activeKey.value = ["2"];
    };
    const tabChanged = async (value: any) => {
      if (value === "1") {
        addReferee.value = false;
      }
    };
    const refereeAdded = async (data: any) => {
      if (data === "CloseModal") {
        activeKey.value = ["1"];
        addReferee.value = false;
      } else {
        activeKey.value = ["1"];
        addReferee.value = false;
        getCandidateDetails(accessToken.value);
      }
    };
    const getCount = (data: any) => {
      if (data === "pending") {
        if (candidateDetails.value.referees.length) {
          const filterUser = candidateDetails.value.referees.filter(
            (itm: any) => {
              return itm.status === "pending";
            }
          );
          return filterUser.length;
        } else {
          return 0;
        }
      } else {
        if (candidateDetails.value.referees.length) {
          const filterUser = candidateDetails.value.referees.filter(
            (itm: any) => {
              return itm.status === "completed";
            }
          );
          return filterUser.length;
        } else {
          return 0;
        }
      }
    };
    const copy = () => {
      clone.value.focus();
      document.execCommand("copy");
    };
    const closewindow = async () => {
      window.close();
    };
    onMounted(async () => {
      getCandidateDetails(accessToken.value);
      activeKey.value = ["1"];
      addReferee.value = false;
      copyUrl.value =
        process.env.VUE_APP_URL + "add-referee/" + accessToken.value;
    });
    return {
      copy,
      clone,
      accessToken,
      pageLoader,
      moment,
      notify,
      sendRefereeRemindar,
      deleteReferee,
      activeKey,
      addReferee,
      addNewReferee,
      tabChanged,
      refereeAdded,
      deleteRefereLoading,
      candidateDetails,
      refereRemainderLoading,
      getCount,
      copyUrl,
      closewindow,
      showDeleteConfirm,
      labelCol: {
        span: 14,
      },
      wrapperCol: {
        span: 10,
      },
    };
  },
  created: function () {
    this.moment = moment;
  },
});
